
export enum PredefinedMessageTypes {
  ADMINS = 'Please contact myself or my administrator to facilitate purchasing more assessments.',
  RENEWAL = 'Please contact myself or my administrator to facilitate renewing my subscription.'
}

// Change support dialog box title  & possibly subtitle
// Admins = Administration Request
// Renewal = Subscription Renewal
export interface SupportMessage {
  uid: string;
  companyName: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}