import { WorkGroup, EvalueeWorkGroup, Assessment } from '@career-scope/models';


export function createEvalueeWorkGroups(assessment: Assessment, workgroups: WorkGroup[]): EvalueeWorkGroup[] {
    const evalueeWorkgroups: EvalueeWorkGroup[] = [];
  
    if (!workgroups) {
      return [];
    }
    workgroups.forEach(group => {
      const evalueeGroup: EvalueeWorkGroup = {
        aptitudeQualify: true,
        aptitudeQualifySEM: true,
        interestRank: 0,
        ...group
      };
  
      const interestRank = assessment.topInterestScores.find(i => i.interestCategory === group.interestCategory)?.rank || 0;
      evalueeGroup.interestRank = interestRank;
  
      // Discern if Workgroup qualifies for the Aptitude
  
      evalueeGroup.aptitudeMinimums.forEach(aMin => {
        const wgQualify = assessment.aptitudeScores.findIndex(aptScore => aptScore.aptitude === aMin.aptitude && aptScore.grade_adjusted_score < aMin.score) === -1;
        evalueeGroup.aptitudeQualify = evalueeGroup.aptitudeQualify && wgQualify;
  
        const wgQualifySEM = assessment.aptitudeScores.findIndex(aptScore => aptScore.aptitude === aMin.aptitude && aptScore.SEM_adjusted_score < aMin.score) === -1;
        evalueeGroup.aptitudeQualifySEM = evalueeGroup.aptitudeQualifySEM && wgQualifySEM;
      });
  
      evalueeWorkgroups.push(evalueeGroup);
    });
    
    return evalueeWorkgroups
  }

export function findWorkgroups(evalueeWorkgroups: EvalueeWorkGroup[] | null, screenType: 'interest' | 'aptitude' | 'default'): EvalueeWorkGroup[] {

    if (!evalueeWorkgroups) {
      return [];
    }
  
    if (screenType === 'interest') {
      return evalueeWorkgroups.filter(grp => grp.interestRank > 0);
    }
  
    if (screenType === 'aptitude') {
      return evalueeWorkgroups.filter(grp => grp.aptitudeQualifySEM);
    }
  
    return evalueeWorkgroups.filter(grp => (grp.interestRank > 0) && (grp.aptitudeQualifySEM));
  }