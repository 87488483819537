import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private _snackbar: MatSnackBar
  ) { }

  openSnackBar(message: string, action: string, config?: MatSnackBarConfig) {
    if (config) {
      this._snackbar.open(message, action, config);
    } else {
      this._snackbar.open(message, action);
    }
  }

  openDismissibleSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openTopSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
