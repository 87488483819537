import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription, throwError } from 'rxjs';
import { tap, take, map, catchError, switchMap } from 'rxjs/operators';
import { convertTimestamp, convertTimestampsPipe } from '../pipes/convert-firestore-timestamp.pipe';
import { EvalueeCountResults, Portal, PortalDashboard, PortalData, SubscriptionChange, Transaction, TransactionWithoutBalance } from '@career-scope/models';
import { AuthService } from './auth.service';
import { FirebaseError } from 'firebase/app';
import { NotificationService } from './notification.service';
import { NPS_SURVEY_RESULTS, NpsSurvey } from '../models/nps-survey';
import { saveAs } from 'file-saver';
import { collection, collectionData, doc, docData, DocumentReference, Firestore, getDocs, limit, query, setDoc, where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class PortalsService {
  portalsSubscription?: Subscription;
  portalFilterSubscription?: Subscription;
  portals = new BehaviorSubject<PortalDashboard[]>([]);
  filteredPortals = new BehaviorSubject<PortalDashboard[]>([]);
  // For customers with multiple portals this gets populated when a portal is retrieved
  customerPortals = new BehaviorSubject<PortalDashboard[]>([]);
  searchValue = new BehaviorSubject<string | null>(null);
  salesRepsList: string[] = []; 
  portalStatusList: string[] = [];
  showFilters = new BehaviorSubject<boolean>(true);

  constructor(private firestore: Firestore, private as: AuthService, private functions: Functions, private notification: NotificationService) {
    this.as.user$.pipe(
      tap(user => {
        if (user) {
          return;
        }

        this.portalsSubscription?.unsubscribe();
        this.portalFilterSubscription?.unsubscribe();
        this.portals.next([]);
        this.filteredPortals.next([]);
        this.customerPortals.next([]);
      })
    ).subscribe();
  }

  // Called from the auth guard when navigating to HQ
  populatePortals(): void {

    // Only subscribe if we haven't already
    if (!this.portalsSubscription) {
      const portalsDocRef = doc(this.firestore, 'dashboards/portals') as DocumentReference<PortalData>;
      this.portalsSubscription = docData<PortalData>(portalsDocRef).pipe(
        convertTimestampsPipe(),
        tap(res => {
          if (res) {
            this.portals.next(res.portalDashboard);
            this.filterPortals();
            this.populateFilterLists();

          } else {
            console.log('Missing data for the portals dashboard');
          }
        })
      ).subscribe();
    }

  }

  populateFilterLists(): void {
    this.salesRepsList = Array.from(new Set(this.portals.value.map(a => a.salesRepUserName)));
    this.portalStatusList = Array.from(new Set(this.portals.value.map(a => a.portalStatus)));
  }

  filterPortals(): void {
    if (this.portalFilterSubscription) {
      this.portalFilterSubscription.unsubscribe();
    }

    this.portalFilterSubscription = combineLatest([this.as.portalFilters, this.searchValue]).pipe(
      map(([filters, searchValue]) => ({ filters, searchValue })),
      tap(res => {
        const { filters, searchValue } = res;
        if (filters && (filters.daysToExpire !== null || filters.balanceLessThan !== null || filters?.salesReps.length > 0 || filters?.statuses.length > 0 || filters.hideExpired) || searchValue) {

          const search = searchValue?.toLowerCase() || null;

          const filteredList = this.portals.value.filter(portal =>
            (!search || portal.customerNumber.toLowerCase().includes(search) || portal.name.toLowerCase().includes(search)) &&
            (filters?.salesReps.length === 0 || filters?.salesReps.includes(portal.salesRepUserName)) &&
            (filters.statuses.length === 0 || filters.statuses.includes(portal.portalStatus)) &&
            (!filters.daysToExpire || !portal.subscriptionEnd || (portal.subscriptionEnd && +((portal.subscriptionEnd.getTime() - new Date().getTime()) / (1000 * 3600 * 24)).toFixed(0) <= filters.daysToExpire)) &&
            (!filters.balanceLessThan || portal.availableAdmins <= filters.balanceLessThan) &&
            (!filters.hideExpired || (portal.subscriptionEnd && portal.subscriptionEnd.getTime() > new Date().getTime())) &&
            (!filters.hideActiveManagers || portal.activeManagers === 0 || !portal.activeManagers)
          );
          this.filteredPortals.next(filteredList);
          return;
        }

        this.filteredPortals.next(this.portals.value);
      })
    ).subscribe();




    // TODO: Code to group by
    // const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]: ( hash[obj[key]] || [] ).concat(obj)}), {});
    // console.log(groupByKey(filteredList, 'customerNumber'));
  }

  getPortal(id: string): Observable<Portal | undefined> {
    const portalDocRef = doc(this.firestore, `portals/${id}`) as DocumentReference<Portal>;
    return docData<Portal>(portalDocRef).pipe(
      convertTimestampsPipe(),
      tap(portal => {
        if (portal) {
          // TODO: Sort somewhere else ... Sort transactions on view
          portal.transactions = portal.transactions.sort((a, b) => (b.transactionDate.getTime()) - (a.transactionDate.getTime()));
          portal.subscriptionChanges = portal.subscriptionChanges.sort((a, b) => (b.transactionDate.getTime()) - (a.transactionDate.getTime()));
          const portals = this.portals.value;
          this.customerPortals.next(portals.filter(p => p.customerNumber === portal.customerNumber));
        }
      })
    );
  }

  addAdministrationUpdate(portal: Portal, transaction: TransactionWithoutBalance) {
    const transactions = Array.from(portal.transactions);

    const balance = transaction.removal ? portal.availableAdmins - transaction.administrations : portal.availableAdmins + transaction.administrations;

    const transactionWithBalance: Transaction = {
      ...transaction,
      adminBalanceAfterTransaction: balance
    };

    transactions.push(transactionWithBalance);
    const availableAdmins = portal.availableAdmins + (transaction.removal ? -1 : 1) * transaction.administrations;
    // TODO: Update % available
    // TODO: Do we want adminBalanceAfterTransaction on the portal?
    this.updatePortal(portal, { transactions, availableAdmins });
  }



  addRenewalDate(portal: Portal, subscriptionChange: SubscriptionChange) {
    const subscriptionChanges = Array.from(portal.subscriptionChanges);
    subscriptionChanges.push(subscriptionChange);
    const subscriptionEnd = subscriptionChange.newSubscriptionDate;

    this.updatePortal(portal, { subscriptionChanges, subscriptionEnd });
  }

  checkUniqueCustomerNumberAndPortalName(customerNumber: string, portalName: string, portalId: string | null) {
    const portalsCollectionRef = collection(this.firestore, 'portals');
    const q = query(portalsCollectionRef,
      where('customerNumber', '==', customerNumber),
      where('name', '==', portalName),
      limit(1));

    const existing = from(getDocs(q));

    return existing.pipe(
      map(snapshot => {
        const res = snapshot.docs.map(doc => doc.data() as Portal);
        if (res.length === 0 || (portalId && res[0].id === portalId)) {
          return customerNumber;
        }
        throw new Error('Customer number and portal name already exist. Please try a different portal name');
      })
    );
  }

  saveExistingPortal(portal: Portal) {
    return this.checkUniqueCustomerNumberAndPortalName(portal.customerNumber, portal.name, portal.id).pipe(
      switchMap(() => this.savePortalObservable(portal))
    );
  }

  savePortalObservable(portal: Portal): Observable<string> {
    const portalDocRef = doc(this.firestore, `portals/${portal.id}`);
    return from(setDoc(portalDocRef, { ...portal, lastModifiedDate: new Date(Date.now()) }, { merge: true }))
      .pipe(
        map(() => portal.id)
      );
  }

  updatePortal(portal: Portal, portalUpdates?: Partial<Portal>) {
    this.savePortal({ ...portal, ...portalUpdates });
  }


  savePortal(portal: Portal): void {
    const portalDocRef = doc(this.firestore, `portals/${portal.id}`);
    setDoc(portalDocRef, { ...portal, lastModifiedDate: new Date(Date.now()) }, { merge: true });
  }

  deletePortal(portal: Portal): Observable<string> {
    const callable = httpsCallable(this.functions, 'deletePortal');
    // Delete portal and subcollections of portal in Firebase function
    return from(callable({ portalId: portal.id })).pipe(
      take(1),
      catchError((error: FirebaseError) => throwError(() => error.message)),
      switchMap(response => {
        // Confirm that response from function is the same as expected portalId
        if (response.data === portal.id) {
          return of(response.data);
        }
        return throwError(() => 'Deleted portal does not match expected portal id');
      })
    );
  }

  rebuildHQDashboard(): void {
    this.notification.openSnackBar('Dashboard rebuild queued', 'Dismiss', { duration: 3000 });
    
    const callable = httpsCallable(this.functions, 'rebuildHQDashboard');
    
    from(callable()).pipe(
      take(1),
      catchError((error: FirebaseError) => {
        this.notification.openSnackBar(error.message, 'Dismiss', { duration: 3000 });
        return throwError(() => error.message);
      }),
      tap(() => this.notification.openSnackBar('HQ Dashboard Rebuilt', 'Dismiss', { duration: 3000 }))
    ).subscribe();
  }

  getCompletedEvalueeCountForLivePortalsBetweenDates(start: string, end: string): Observable<EvalueeCountResults> {
    console.log('getCompletedEvalueeCountForLivePortalsBetweenDates', start, end);
    const callable = httpsCallable<{start: string, end: string}, EvalueeCountResults>(this.functions, 'getCompletedEvalueeCountForLivePortalsBetweenDates');
    return from(callable({ start, end })).pipe(take(1),
      map(result => result.data),
      catchError((error: FirebaseError) => {
        this.notification.openSnackBar(error.message, 'Dismiss');
        return throwError(() => error.message);
      }
      ));
  }

  getNpsSurveyResults(start: string, end: string) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const npsSurveyCollectionRef = collection(this.firestore, NPS_SURVEY_RESULTS);
    const q = query(npsSurveyCollectionRef, 
                    where('completedDate', '>=', startDate), 
                    where('completedDate', '<=', endDate));
    
    return (collectionData(q, { idField: 'id' }) as Observable<NpsSurvey[]>).pipe(
      take(1),
      convertTimestampsPipe(),
      map(results => {
        const header = 'Full Name,Email,Completed Date,Satisfaction Score,Comments,Completed Seekers,Portal Name,Account ID (Zoho),Portal ID,Customer Number\n';

        const data = results.map(result => {
          const { fullName, email, satisfaction, comments, completedEvaluees, portalName, crmAccountId, portalId, customerNumber } = result;
          const completedDate = convertTimestamp(result.completedDate);

          // remove all commas
          const csv = `${fullName},${email},${completedDate.toDateString()},${satisfaction},${comments.replace(/,/g, ' ').replace(/\n/g, ' ')},${completedEvaluees},${portalName.replace(/,/g, ' ')},${crmAccountId},${portalId},${customerNumber}`;
          return csv;
        }).join('\n');

        const csv = header + data;

        const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8' });
        saveAs.saveAs(blob, `NPS_Results_${start}--${end}.csv`);

        return results;
      })
    );
  }
}
