import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-manager-profile-dialog',
    templateUrl: './manager-profile-dialog.component.html',
    styleUrls: ['./manager-profile-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatError, MatButton]
})
export class ManagerProfileDialogComponent implements OnInit {

  managerProfileForm!: FormGroup

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ManagerProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {fullName: string}
  ) { }

  ngOnInit(): void {
    this.managerProfileForm = this.fb.group({
      name: ['',[Validators.required]]
    });
  }

  get name() {
    return this.managerProfileForm.get('name') as FormControl;
  }

  donePressed() {
    this.dialogRef.close(this.name?.value);
  }

}
