import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CanActivate } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PredefinedMessageTypes } from '../models/supportMessage.model';
import { PermissionsService } from '../services/permissions.service';
import { PortalService } from '../services/portal.service';
import { SupportMessageDialogComponent } from '../support-message-dialog/support-message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PortalExpiredGuard implements CanActivate {

  constructor(private permissions: PermissionsService, private ps: PortalService, private dialog: MatDialog) { }

  canActivate(): Observable<boolean> {
    return this.permissions.portalExpired().pipe(
      map(expired => {
        if (!expired) {
          return true;
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = PredefinedMessageTypes.RENEWAL;
        this.dialog.open(SupportMessageDialogComponent, dialogConfig);

        return false;
      })
    );
  }

}
