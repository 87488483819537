<div mat-dialog-title>
  <h1>My Profile</h1>
  <div>
    <p>Please complete your profile. Keeping this information up-to-date helps us continue to focus our development
      where it matters most for your services.</p>
  </div>
  <hr>
</div>

<div mat-dialog-content>
  <div class="profile-form">
    <form [formGroup]="managerProfileForm">

      <div class="form-row">
        <div class="form-col">
          <mat-form-field appearance="fill">
            <mat-label>What is your name?</mat-label>
            <input matInput placeholder="name" formControlName="name">
            <mat-error *ngIf="name?.invalid">
              Name is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <button mat-raised-button color="primary" class="done" [disabled]="managerProfileForm.invalid"
          (click)="donePressed()">Done</button>
      </div>

    </form>
  </div>
</div>