import { Routes } from '@angular/router';
import { AuthGuard } from './user/auth.guard';
import { UserResolverService } from './services/user-resolver.service';
import { PortalExistsGuard } from './guards/portal-exists.guard';
import { HqAccessGuard } from './guards/hq-access.guard';
import { PortalExpiredGuard } from './guards/portal-expired.guard';
import { reportingAccess } from './guards/reporting-access.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./user/user-routing').then(m => m.routes)
  },
  {
    path: 'hq',
    loadChildren: () => import('./hq/hq-routing').then(m => m.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-search', loadComponent: () => import('./evaluee-search-bar/admin-search-table.component').then(m => m.AdminSearchTableComponent), canActivate: [AuthGuard], resolve: { user: UserResolverService }
  },
  {
    path: ':portalId',
    children: [
      { path: 'invitations', loadComponent: () => import('./invitations/invitations.component').then(m => m.InvitationsComponent), canActivate: [AuthGuard, PortalExistsGuard] },
      { path: 'invitations/batch', loadComponent: () => import('./invitations/batch-pre-registration/batch-pre-registration.component').then(m => m.BatchPreRegistrationComponent), canActivate: [AuthGuard, PortalExistsGuard] },
      { path: 'reports', loadComponent: () => import('./reports/reports.component').then(m => m.ReportsComponent), canActivate: [AuthGuard, PortalExistsGuard] },
      { path: 'reports/evaluee/:id', loadComponent: () => import('./evaluee/evaluee.component').then(m => m.EvalueeComponent), canActivate: [AuthGuard, PortalExpiredGuard] },
      { path: 'reports/evaluee/:id/aptfull/:task', loadComponent: () => import('./aptitudes/aptitudes.component').then(m => m.AptitudesComponent), canActivate: [AuthGuard, HqAccessGuard, PortalExpiredGuard] },
      { path: 'settings', loadComponent: () => import('./settings/settings.component').then(m => m.SettingsComponent), canActivate: [AuthGuard] },
      { path: 'settings/:card', loadComponent: () => import('./settings/settings.component').then(m => m.SettingsComponent), canActivate: [AuthGuard] },
      { path: 'managers', loadComponent: () => import('./managers/managers.component').then(m => m.ManagersComponent), canActivate: [AuthGuard] },
      { path: 'managers/:id', loadComponent: () => import('./manager-detail/manager-detail.component').then(m => m.ManagerDetailComponent), canActivate: [AuthGuard] },
      { path: 'dash', loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent), canActivate: [AuthGuard] },
      { path: 'reporting', loadComponent: () => import('./reporting/reporting-details/reporting-details.component').then(m => m.ReportingDetailsComponent), canActivate: [AuthGuard, reportingAccess] },
    ]
  },
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: '/login'
  }
];

