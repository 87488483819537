import { Component } from '@angular/core';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PortalService } from '../../../services/portal.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'app-change-sites-dialog',
    templateUrl: './change-sites-dialog.component.html',
    styleUrls: ['./change-sites-dialog.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, MatFormField, MatLabel, MatSelect, NgFor, MatOption, AsyncPipe]
})
export class ChangeSitesDialogComponent  {

  constructor(
    public dialogRef: MatDialogRef<ChangeSitesDialogComponent>,
    private router: Router,
    public ps: PortalService,
  ) { }


  switchTo(portal: string) {
    this.router.navigate([portal + '/dash']);
    this.dialogRef.close();
    
  }

}
