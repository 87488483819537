import { Injectable, inject } from '@angular/core';
import { Evaluee, EvalueeFilters, EvalueeStatusOptions } from '@career-scope/models';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, filter, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { convertTimestampsPipe } from '../pipes/convert-firestore-timestamp.pipe';
import { AuthService } from './auth.service';
import { collection, collectionData, collectionGroup, CollectionReference, endBefore, Firestore, getCountFromServer, limit, limitToLast, orderBy, query, QueryConstraint, startAfter, where } from '@angular/fire/firestore';
import { Sort } from '@angular/material/sort';
import { NotificationService } from './notification.service';

export interface Pagination {
  pageIndex: number;
  previousPageIndex: number | undefined;
  pageSize: number;
  firstEvaluee: Evaluee | null;
  lastEvaluee: Evaluee | null;
}

export interface FilterState {
  evalueeFilters: EvalueeFilters | null;
  search: string | null;
  sort: Sort;
  pagination: Pagination,
  evalueeStatus: EvalueeStatusOptions[] | null;
  evalueeListType: 'discover' | 'assess' | null;
}

@Injectable({
  providedIn: 'root'
})
export class EvalueeListService {
  firestore = inject(Firestore);
  as = inject(AuthService);
  notificationService = inject(NotificationService);

  portalId = '';

  sortAndPaginationState = new BehaviorSubject<Omit<FilterState, 'evalueeFilters'>>({
    search: null,
    sort: { active: 'lastActivityDate', direction: 'desc' },
    pagination: { pageIndex: 0, previousPageIndex: undefined, pageSize: 20, firstEvaluee: null, lastEvaluee: null },
    evalueeStatus: [],
    evalueeListType: null
  });

  private evalueeFilters$ = this.as.evalueeFilters.pipe(
    filter(evalueeFilters => evalueeFilters !== null),
    startWith(null), // Ensure that pairwise has an initial value to pair with the first emission
    pairwise(), // Emit the previous and current values as a pair
    filter(([prev, curr]) => {
      return JSON.stringify(prev) !== JSON.stringify(curr); // Compare the previous and current values
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, curr]) => curr), // Only pass along the current value
    tap(() => this.resetPagination())
  );

  filterState$: Observable<FilterState> = combineLatest([
    this.evalueeFilters$,
    this.sortAndPaginationState
  ]).pipe(
    map(([evalueeFilters, sortAndPagination]) => {
      return {
        evalueeFilters,
        ...sortAndPagination
      };
    }),
    shareReplay(1) // Used to prevent multiple subscriptions to the same observable, which causes pagination to reset in evalueeFilters$ when navigating back
  );

  evalueesTableData$: Observable<{ evaluees: Evaluee[], evalueeCount: number; counselor: string; }> = this.filterState$.pipe(
    // switchMap used as we want to use the latest value of evalueeFilters and cancel previous requests
    switchMap(filterState => {
      if (!filterState.evalueeStatus || filterState.evalueeStatus.length === 0 || filterState.evalueeListType === null) {
        return of({ evaluees: [], evalueeCount: 0, counselor: '' });
      }

      const evalueesCollection = collection(this.firestore, `portals/${this.portalId}/evaluees`);

      const baseConstraints: QueryConstraint[] = [];

      if (filterState.search) {
        baseConstraints.push(where('uid', '==', filterState.search));
      } else {

        const filterStatuses: EvalueeStatusOptions[] = filterState.evalueeListType === 'assess' ? !filterState.evalueeFilters?.assessStatus ? ['new', 'in progress'] : [filterState.evalueeFilters?.assessStatus] : filterState.evalueeStatus;

        baseConstraints.push(...this.applyEvalueeFilters(filterState.evalueeFilters), where('status', 'in', filterStatuses));
      }

      const constraintsWithPageAndSort = filterState.search ? baseConstraints : this.applyPaginationAndSorting([...baseConstraints], filterState.sort, filterState.pagination);

      const evaluees$ = (collectionData(query(evalueesCollection, ...constraintsWithPageAndSort)) as Observable<Evaluee[]>).pipe(convertTimestampsPipe(), catchError(err => { console.error(err); return of([]); }));
      const evalueeCount$ = from(getCountFromServer((query(evalueesCollection, ...baseConstraints)))).pipe(map(aggregateField => aggregateField.data().count));

      return combineLatest([evaluees$, evalueeCount$]).pipe(
        map(([evaluees, evalueeCount]) => ({ evaluees, evalueeCount, counselor: filterState.evalueeFilters?.counselor || '' })),
        catchError(err => {
          console.error(err);
          return of({ evaluees: [], evalueeCount: 0, counselor: '' });
        })
      );
    })
  );

  // This will cause a double fire to the database as pagination is apart of filterState$
  // No way to avoid this as pagination and evalueeFilters are separate observables
  private resetPagination() {
    const currentState = this.sortAndPaginationState.getValue();
    const updatedState = {
      ...currentState,
      pagination: { ...currentState.pagination, pageIndex: 0, previousPageIndex: undefined, firstEvaluee: null, lastEvaluee: null }
    };

    this.sortAndPaginationState.next(updatedState);
  }

  searchEvaluees(fullName: string, restrictedViewCounselor: string | undefined): Observable<Evaluee[]> {
    const evalueesCollection = collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>;
    const constraints = [where('portalId', '==', this.portalId), where('lowerCaseFullName', '>=', fullName.toLowerCase()), where('lowerCaseFullName', '<=', fullName.toLowerCase() + '\uf8ff')];

    if (restrictedViewCounselor) {
      constraints.push(where('counselor.name', '==', restrictedViewCounselor));
    }

    return collectionData(query(evalueesCollection, ...constraints, limit(20))).pipe(
      convertTimestampsPipe()
    );
  }

  adminSearchEvaluees(fullName: string, portals: string[]) {
    if (portals.length >= 30 || portals.length === 0) {
      this.notificationService.openDismissibleSnackbar('Too many portals to search. Please reach out to support for assistance.');
      throw new Error('Too many portals to search');
    }

    const evalueesCollection = collectionGroup(this.firestore, `evaluees`) as CollectionReference<Evaluee>;
    const constraints = [where('portalId', 'in', portals), where('lowerCaseFullName', '>=', fullName.toLowerCase()), where('lowerCaseFullName', '<=', fullName.toLowerCase() + '\uf8ff')];

    return collectionData(query(evalueesCollection, ...constraints, limit(20))).pipe(
      convertTimestampsPipe()
    );
  }

  // ANY UPDATES TO SORTING OR FILTERING WILL REQUIRE A NEW INDEX IN FIRESTORE
  applyEvalueeFilters(evalueeFilters: EvalueeFilters | null): QueryConstraint[] {
    if (!evalueeFilters) {
      return [];
    }

    const constraints: QueryConstraint[] = [];

    if (evalueeFilters.counselor && evalueeFilters.counselor !== 'all') {
      constraints.push(where('counselor.name', '==', evalueeFilters.counselor));
    }

    if (evalueeFilters.categories && evalueeFilters.categories.length > 0) {
      const categoriesWithIdentifier = evalueeFilters.categories.filter(category => category.identifier);

      // If only one category search the full identifiers array to see if it contains the identifier
      if (categoriesWithIdentifier.length === 1) {
        constraints.push(where('identifiers', 'array-contains', { id: categoriesWithIdentifier[0].id, identifier: categoriesWithIdentifier[0].identifier }));
      }

      // If more than one category search for matching set of identifiers
      if (categoriesWithIdentifier.length > 1) {
        constraints.push(where('identifiers', '==', categoriesWithIdentifier.map(category => ({ id: category.id, identifier: category.identifier }))));
      }
    }

    return constraints;
  }

  // ANY UPDATES TO SORTING OR FILTERING WILL REQUIRE A NEW INDEX IN FIRESTORE
  applyPaginationAndSorting(constraints: QueryConstraint[], sort: Sort, pagination: Pagination): QueryConstraint[] {
    // counselorName is stored in the database as counselor.name
    let sortActive = sort.active;

    switch (sort.active) {
      case 'fullName':
        sortActive = 'lowerCaseFullName';
        break;
      case 'counselorName':
        sortActive = 'counselor.name';
        break;
      default:
        sortActive = sort.active;
    }

    constraints.push(orderBy(sortActive, sort.direction === 'asc' ? 'asc' : 'desc'));

    // Secondary/tertiary sort fields order matters as it affects how the indexes in firestore are built
    // Whatever the last sort option is will be what determines asc/desc for __name__ sorting in firestore

    // Secondary sort field 
    if (sort.active !== 'lastActivityDate') {
      constraints.push(orderBy('lastActivityDate', 'desc'));
    }

    // Tertiary sort field
    if (sort.active !== 'lowerCaseFullName') {
      constraints.push(orderBy('lowerCaseFullName', 'asc'));
    }

    return this.applyPagination(constraints, pagination, sortActive);
  }

  applyPagination(constraints: QueryConstraint[], pagination: Pagination, sortActive: string): QueryConstraint[] {
    const { pageIndex, pageSize, lastEvaluee, firstEvaluee, previousPageIndex } = pagination;

    if (pageIndex === 0) {
      return [...constraints, limit(pageSize)];
    }

    const getPaginationArgs = (evaluee: Evaluee | null) => {
      if (!evaluee) return [];

      const args = [
        sortActive === 'counselor.name' ? evaluee.counselor.name : evaluee[sortActive as keyof Evaluee]
      ];

      if (sortActive !== 'lastActivityDate') {
        args.push(evaluee['lastActivityDate']);
      }

      if (sortActive !== 'fullName' && sortActive !== 'lowerCaseFullName') {
        args.push(evaluee['lowerCaseFullName']);
      }

      return args;
    };

    if (pageIndex > (previousPageIndex || 0) && lastEvaluee) {
      return [...constraints, startAfter(...getPaginationArgs(lastEvaluee)), limit(pageSize)];
    }

    if (pageIndex < (previousPageIndex || 0) && firstEvaluee) {
      return [...constraints, endBefore(...getPaginationArgs(firstEvaluee)), limitToLast(pageSize)];
    }

    return [...constraints, limit(pageSize)];
  }

  resetEvalueeListService() {
    this.portalId = '';
  }
}
