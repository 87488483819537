<ng-container *ngIf="this.as.user$ | async as user">
  <div *ngIf="router.url.includes('/hq') && user" class="hq-notice" [@fold]>
    <div class="container">
      <div class="hq-notice-portal">Current Portal: {{ user.lastPortal }}</div>
    </div>
  </div>

  <div
    *ngIf="!router.url.includes('/hq') && (portalExpired$ | async)"
    class="exp-notice"
    [@fold]
  >
    <div class="container">
      <div class="exp-notice-portal">
        The portal subscription period has ended. Functionality is reduced until
        the portal is reactivated.
        <button
          mat-raised-button
          class="reactive-button"
          aria-label="Reactivate portal"
          (click)="reactivatePortal()"
        >
          <mat-icon>add</mat-icon> Reactivate portal
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      !router.url.includes('/hq') &&
      (portalExpired$ | async) === false &&
      (ps.portal | async)?.portalStatus === 'demo'
    "
    class="exp-notice"
    [@fold]
  >
    <div class="container">
      <div class="exp-notice-portal">
        This CareerScope assessment portal is accessible for demonstration
        purposes only.
      </div>
    </div>
  </div>
</ng-container>
