<div mat-dialog-content>
  <h2>Change Sites</h2>
  <div *ngIf="ps.availablePortalSwitcher | async as portals">
    <mat-form-field appearance="fill">
      <mat-label>Portal</mat-label>
      <mat-select (selectionChange)="switchTo($event.value)">
        <mat-option *ngFor="let portal of portals" [value]="portal.id">
          {{portal.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>