<div mat-dialog-title>
  <div class="dialog-with-close">
    <h1>Support</h1>
    <button mat-icon-button mat-dialog-close aria-label="close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p *ngIf="data !== predefinedMessageTypes.RENEWAL">Tell us what you need help with.<br>Please provide details to expedite the resolution.</p>
  <p *ngIf="data === predefinedMessageTypes.RENEWAL">Send your renewal message below,<br>please add any additional information if needed.</p>
</div>
<mat-dialog-content>
  <form [formGroup]="form" *ngIf="form">
    <div class="form-field-row">
      <mat-form-field appearance="outline" class="margin-right">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="email?.errors?.required">
          Email is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <!-- TODO: Mask for phone number -->
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" maxlength="20">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>I need help with...</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" formControlName="message"></textarea>
        <mat-error *ngIf="message?.errors?.required && message?.touched">
          Message is required
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions>
    <button mat-flat-button cdkFocusInitial (click)="sendMessage()" style="width: 100%" color="primary">Send</button>
  </div>
</mat-dialog-content>