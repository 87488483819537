import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, take, map, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { OccupationsService } from '../services/occupations.service';
import { PortalService } from '../services/portal.service';
import { PortalsService } from '../services/portals.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private as: AuthService,
    private router: Router,
    private ps: PortalService,
    private pss: PortalsService,
    private os: OccupationsService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean> {

    const portalId = next.paramMap.get('portalId') || '';

    return this.as.user$.pipe(
      take(1),
      map(user => {
        const path = next.routeConfig?.path;
        const success = !!(user &&
          (
            user.hqAccess
            || (user.portals?.indexOf(portalId) !== -1 && !path?.includes('hq'))
            || (user.portals && user.portals.length > 1 && user.admin && path?.includes('admin-search'))
          ));
        if (success) {
          // Load Portal here instead of in each component
          if (portalId) {
            this.ps.loadPortal(portalId, user.uid);
            if (path?.includes('reports/evaluee/')) {
              this.os.loadOccupations();
              this.os.loadOnet27Occupations();
              this.os.loadWorkgroups();
              this.os.loadClusters();
            }
          }
          if (user?.hqAccess) {
            this.pss.populatePortals();
          }
        } else {
          // TODO: Log access denied record
          console.log('access denied', user, portalId);
        }
        return success;
      }),

      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
