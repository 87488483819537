import { inject } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
import { map, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

export function reportingAccess() {
  const permissionsService = inject(PermissionsService);
  const user$ = inject(AuthService).user$;
  const router = inject(Router);

  return permissionsService.reporting().pipe(
      switchMap(access => {
        if (access) {
          return of(true);
        }
        return user$.pipe(
          map(user => {
            if(user) {
              router.navigateByUrl(user.lastPortalId + '/dash');
            }
            return false;
          })
        );
      })
    );
}