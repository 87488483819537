<div class="flex">
  <div class="search">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input matInput type="text" placeholder="Search..." [formControl]="searchControl" maxlength="50">
      <mat-icon matPrefix>search</mat-icon>
      <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="flex-right">
    <div *ngIf="viewDeveloperContent$ | async" class="margin-right: 2rem">
      <button mat-button color="primary" (click)="rebuildDashboard()">Rebuild HQ Dashboard</button>
    </div>
    <div>
      <a mat-button color="primary" routerLink="/hq/new">Create New Portal</a>
    </div>
  </div>
</div>