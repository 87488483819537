import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap, Subscription } from 'rxjs';
import { PortalsService } from '../../../services/portals.service';
import { PermissionsService } from '../../../services/permissions.service';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatIconButton, MatButton, MatAnchor } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'career-scope-hq-portal-search',
    templateUrl: './hq-portal-search.component.html',
    styleUrls: ['./hq-portal-search.component.scss'],
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        MatIcon,
        MatPrefix,
        NgIf,
        MatIconButton,
        MatSuffix,
        MatButton,
        MatAnchor,
        RouterLink,
        AsyncPipe,
    ],
})
export class HqPortalSearchComponent implements OnInit, OnDestroy {
  permissions = inject(PermissionsService);
  searchControl = new FormControl<string | null>(null, { nonNullable: false });
  searchControlSubscription!: Subscription;

  viewDeveloperContent$ = this.permissions.hasRole('ViewDeveloperContent');

  constructor(private pss: PortalsService) { }

  ngOnInit(): void {
    this.searchControl.setValue(this.pss.searchValue.value, { emitEvent: false });
    this.searchControlSubscription = this.searchControl.valueChanges.pipe(
      tap(value => {
        this.pss.searchValue.next(value);
      })
    ).subscribe();
  }

  rebuildDashboard() {
    this.pss.rebuildHQDashboard();
  }

  ngOnDestroy(): void {
    this.searchControlSubscription?.unsubscribe();
  }
}
