import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';

import { AdminRegister } from '../models/register.model';
import { AdminUser, EvalueeFilters, Manager, PortalFilters } from '@career-scope/models';
import { NotificationService } from './notification.service';
import { Auth, authState, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { collection, doc, docData, DocumentReference, Firestore, getDoc, getDocs, limit, query, setDoc, updateDoc, where, writeBatch } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<AdminUser | null | undefined>;
  type: 'login' | 'signup' | 'reset' | 'invited' = 'login';
  loading = false;
  serverMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  defaultFilters: PortalFilters = { search: null, daysToExpire: null, balanceLessThan: null, salesReps: [], statuses: [], hideExpired: false, hideActiveManagers: false };
  defaultEvalueeFilters: EvalueeFilters = { counselor: '', categories: [], assessStatus: null };
  portalFilters: BehaviorSubject<PortalFilters | null> = new BehaviorSubject<PortalFilters | null>(null);
  evalueeFilters: BehaviorSubject<EvalueeFilters | null> = new BehaviorSubject<EvalueeFilters | null>(null);

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private router: Router,
    private notif: NotificationService
  ) {
    this.user$ = authState(this.auth).pipe(
      switchMap(user => {
        if (user) {
          const userDocRef = doc(this.firestore, `adminUsers/${user.uid}`) as DocumentReference<AdminUser>;
          return docData(userDocRef).pipe(
              map((adminUser: AdminUser | undefined) => {
                if (!adminUser) {
                  return undefined;
                }

                if (adminUser?.deactivatedDate) {
                  this.signOut('Your account has been deactivated. Please contact your administrator.');
                  return undefined;
                }

                if (!this.portalFilters.value) {
                  this.portalFilters.next(adminUser.portalFilters || this.defaultFilters);
                }

                if (!this.evalueeFilters.value) {
                  this.evalueeFilters.next(adminUser.evalueeFilters || this.defaultEvalueeFilters);
                }
                return adminUser;
              }),
            );
        } else {
          return of(undefined);
        }
      })
    );
  }

  currentUser(): Promise<AdminUser | null | undefined> {
    return this.user$.pipe(first()).toPromise();
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isSignup() {
    return this.type === 'signup';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get isInvited() {
    return this.type === 'invited';
  }

  async signIn(email: string, password: string, invitation?: AdminRegister) {
    this.loading = true;
    this.serverMessage.next('');
  
    try {
      let user: AdminUser | null = null;
  
      if (this.isLogin) {
        const credential = await signInWithEmailAndPassword(this.auth, email, password);
        user = await this.getUserData(credential.user.uid);
      } else if (this.isInvited && invitation) {
        const credential = await createUserWithEmailAndPassword(this.auth, email, password);
        user = await this.registerUser(credential.user.uid, invitation);
      } else if (this.isPasswordReset) {
        await sendPasswordResetEmail(this.auth, email);
        this.notif.openSnackBar('Password Reset Email is Queued for Delivery', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
  
      if (user && user.lastPortalId) {
        this.router.navigate([`${user.lastPortalId}/dash`]);
      }
    } catch (err) {
      this.handleSignInError(err, email, password, invitation);
    } finally {
      this.loading = false;
    }
  }
  
  private async getUserData(uid: string): Promise<AdminUser | null> {
    const userDocRef = doc(this.firestore, `adminUsers/${uid}`) as DocumentReference<AdminUser>;
    const userSnapshot = await getDoc(userDocRef);
    return userSnapshot.exists() ? userSnapshot.data() : null;
  }
  
  private handleSignInError(err: unknown, email: string, password: string, invitation?: AdminRegister) {
    const error = String(err);
    if (error.includes('email-already-in-use') && this.isInvited && invitation) {
      this.registerUserPreviouslyAuthed(email, password, invitation);
    } else {
      this.serverMessage.next(error);
    }
  }

  private async registerUserPreviouslyAuthed(email: string, password: string, invitation: AdminRegister) {
    await signInWithEmailAndPassword(this.auth, email, password)
      .then(async credential => {
        if (credential?.user?.uid) {
          const newUser = await this.registerUser(credential.user.uid, invitation);
          this.router.navigate([newUser.lastPortalId + '/dash']);
        }
      })
      .catch(err => { this.serverMessage.next('This email is already in use - attempted to sign in and received error: ' + String(err)); });

  }

  async signOut(message?: string) {
    await signOut(this.auth);
    console.log(this.router.url);
    //window.location.href = this.router.url;

    if (message) {
      this.notif.openSnackBar(message, 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
    // return this.router.navigate(['/login']);
  }

  // Register Invitation
  private async registerUser(userId: string, invitation: AdminRegister): Promise<AdminUser> {
    const userDocRef = doc(this.firestore, `adminUsers/${userId}`) as DocumentReference<AdminUser>;
    const userSnapshot = await getDoc(userDocRef);
    console.log('User Snapshot:', userSnapshot.data());
    const existingUser = userSnapshot.exists() ? userSnapshot.data() : null

    // If existing user, add new portal Id to list
    // Else add user record - add portal in regsitration to list of permissioned portals
    const newUser: AdminUser = existingUser ? {
      ...existingUser,
      portals: [...existingUser.portals || [], invitation.portalId],
      lastPortalId: invitation.portalId
    } : {
      uid: userId,
      name: invitation.name,
      email: invitation.email,
      admin: invitation.admin || false,
      canInviteAdmin: invitation.canInviteAdmin || false,
      restrictViewEvaluees: invitation.restrictViewEvaluees || false,
      hqAccess: false,
      showDev: false,
      portals: [invitation.portalId],
      lastPortalId: invitation.portalId,
      lastPortal: invitation.portalName,
      deactivatedDate: null,
      evalueeFilters: null,
      lastNpsSurveyCompletedDate: null,
      lastNpsSurveyCompletedId: null,
      createdDate: new Date(Date.now())
    };

    try {
      // Set new user
      const userDocRef = doc(this.firestore, `adminUsers/${userId}`);
      await setDoc(userDocRef, <AdminUser> newUser);

      // Create a batch
      const batch = writeBatch(this.firestore);

      // Set portal\manager\doc to status: active
      const managerDocRef = doc(this.firestore, `portals/${invitation.portalId}/managers/${invitation.portalManagerDocId}`);
      batch.update(managerDocRef, <Partial<Manager>> { status: 'active', authUserId: userId });
      // Set AdminRegistration status to registered
      const registrationDocRef = doc(this.firestore, `adminRegistrations/${invitation.id}`);
      batch.update(registrationDocRef, <Partial<AdminRegister>> { status: 'registered' });

      // Commit the batch
      await batch.commit();

      return newUser;
    } catch (err) {
      console.error('Error registering user', String(err));
      this.serverMessage.next(String(err));
      throw err;
    }
  }

  // Navigate to portal "dashboard"


  // public async repairAccount() {
  //   const user = await this.afAuth.currentUser;
  //   this.updateUserData(user);
  // }


  // This gets set everytime a portal is loaded from the portal service "loadPortal"
  public async setLastPortal(portalId: string, portalName: string, authUserId?: string) {
    const user = await this.currentUser();

    if (!user) {
      console.log('No user logged in');
      return;
    }

    try {
      // Update admin user
      const userDocRef = doc(this.firestore, 'adminUsers', user.uid);
      await updateDoc(userDocRef, <Partial<AdminUser>> {
        lastPortalId: portalId,
        lastPortal: portalName,
        lastLoggedIn: new Date()
      });

      // Update manager if authUserId is provided
      if (authUserId) {
        const managersRef = collection(this.firestore, `portals/${portalId}/managers`);
        const q = query(managersRef,
          where('authUserId', '==', authUserId),
          limit(1)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const managerDoc = querySnapshot.docs[0];
          updateDoc(managerDoc.ref, <Partial<Manager>> {
            lastLoggedIn: new Date()
          });
        }
      }
    } catch (error) {
      console.error('Error updating last portal information:', error);
    }
  }

  public async updatePortalAccess(user: AdminUser, portalId: string) {
    const userDocRef = doc(this.firestore, `adminUsers/${user.uid}`);

    const updatedPortals = user.portals?.includes(portalId)
      ? user.portals.filter(id => id !== portalId)
      : [...(user.portals || []), portalId];

    try {
      await updateDoc(userDocRef, <Partial<AdminUser>> { portals: updatedPortals });
    } catch (error) {
      console.error('Error updating portal access:', error);
      throw error;
    }
  }


  public async updateAdminUser(userUpdate: Partial<AdminUser>) {
    if (userUpdate) {
      if (!userUpdate.uid) {
        const user = await this.currentUser();
        userUpdate.uid = user?.uid;
      }

      if (userUpdate.uid) {
        const userDocRef = doc(this.firestore, `adminUsers/${userUpdate.uid}`);
        await updateDoc(userDocRef, <Partial<AdminUser>> userUpdate);
      } else {
        console.error('No user UID found for updating');
      }
    }
  }
}
