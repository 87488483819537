import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AdminUser } from '@career-scope/models';
import { Observable, take } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<Observable<AdminUser| null | undefined>> {

  constructor(private auth: AuthService) { }

  resolve(): Observable<AdminUser | null | undefined> {
    return this.auth.user$.pipe(take(1));
  }
}
