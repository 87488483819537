export interface NpsSurvey {
  satisfaction: number;
  comments: string;
  version: number;
  fullName: string;
  email: string;
  crmAccountId: string;
  portalName: string;
  portalId: string;
  title: 'Admin' | 'Counselor'
  completedDate: Date;
  uid: string;
  adminUserUid: string;
  customerNumber: string;
  completedEvaluees: number;
}

export const NPS_SURVEY_RESULTS = 'npsSurveyResults'