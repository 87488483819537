import { Injectable, inject } from '@angular/core';
import { Assessment, Evaluee, EvalueeWorkGroup, WorkGroup } from '@career-scope/models';
import { createEvalueeWorkGroups, findWorkgroups } from '@career-scope/utils/find-workgroups';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { OccupationGroup } from '../models/occupations.model';
import { Cluster, Pathway } from '../models/pathways.model';
import { EvalueeService } from './evaluee.service';
import { doc, docData, DocumentReference, Firestore } from '@angular/fire/firestore';




@Injectable({
  providedIn: 'root'
})
export class OccupationsService {
  es = inject(EvalueeService);
  occupationGroups: BehaviorSubject<OccupationGroup[] | null> = new BehaviorSubject<OccupationGroup[] | null>(null);
  onet27occupationGroups: BehaviorSubject<OccupationGroup[] | null> = new BehaviorSubject<OccupationGroup[] | null>(null);
  workgroups: BehaviorSubject<WorkGroup[] | null> = new BehaviorSubject<WorkGroup[] | null>(null);
  clusters: BehaviorSubject<Cluster[] | null> = new BehaviorSubject<Cluster[] | null>(null);
  evalueeWorkGroups = new BehaviorSubject<EvalueeWorkGroup[] | null>(null);
  dotWorkGroups = new BehaviorSubject<EvalueeWorkGroup[] | null>(null);
  evalueeOccupationGroups = new BehaviorSubject<OccupationGroup[] | null>(null);
  evalueeLegacyOccupationGroups = new BehaviorSubject<OccupationGroup[] | null>(null);
  aptitudeOnly = false;

  constructor(
    private firestore: Firestore
  ) {
    this.populateEvalueeWorkGroups();
    this.populateDOTWorkgroups();
    this.populateEvalueeOccupationGroups();

  }

  populateEvalueeWorkGroups() {
    // TODO: maybe shift this to be it's own observable rather than using a behavior subject
    combineLatest([this.es.assessment, this.workgroups]).pipe(
      tap(([assessment, workgroups]) => {
        if (!assessment || !workgroups) {
          this.evalueeWorkGroups.next(null);
          return;
        }

        const evalueeWorkGroups = createEvalueeWorkGroups(assessment, workgroups);

        this.evalueeWorkGroups.next(evalueeWorkGroups);
      })
    ).subscribe();
  }

  populateDOTWorkgroups() {
    combineLatest([this.es.evaluee, this.evalueeWorkGroups]).pipe(
      tap(([evaluee, evalueeWorkGroups]) => {
        if (!evaluee || !evalueeWorkGroups) {
          return;
        }

        const dotWorkGroups = this.getDOTWorkgroups(evaluee, evalueeWorkGroups);
        this.dotWorkGroups.next(dotWorkGroups);
      })
    ).subscribe();
  }

  populateEvalueeOccupationGroups() {
    combineLatest([this.dotWorkGroups, this.onet27occupationGroups]).pipe(
      tap(([dotWorkGroups, onet27occupationGroups]) => {
        if (!dotWorkGroups || !onet27occupationGroups) {
          return;
        }

        this.evalueeOccupationGroups.next(this.findOccupations(dotWorkGroups, onet27occupationGroups));

      })
    ).subscribe();

    combineLatest([this.dotWorkGroups, this.occupationGroups]).pipe(
      tap(([dotWorkGroups, occupationGroups]) => {
        if (!dotWorkGroups || !occupationGroups) {
          return;
        }

        this.evalueeLegacyOccupationGroups.next(this.findOccupations(dotWorkGroups, occupationGroups));

      })
    ).subscribe();
  }

  getDOTWorkgroups(evaluee: Evaluee, evalueeWorkGroups: EvalueeWorkGroup[]) {
    // Return aptitude only workgroups if the report option is set to aptitude only
    if (evaluee.reportOption?.aptitudeOnly) {
      this.aptitudeOnly = true;
      return findWorkgroups(evalueeWorkGroups, 'aptitude');
    }

    const workGroups = findWorkgroups(evalueeWorkGroups, 'default');

    // If there are workgroups using default (interest and aptitude) then return those
    if (workGroups.length > 0) {
      this.aptitudeOnly = false;
      return workGroups;
    }

    // If there are no workgroups using default, then return the aptitude only workgroups
    this.aptitudeOnly = true;
    return findWorkgroups(evalueeWorkGroups, 'aptitude');
  }

  loadOccupations() {
    if (!this.occupationGroups.value) {
      const occupationGroupsDocRef = doc(this.firestore, 'occupations/occupationGroups') as DocumentReference<{ occupationGroups: OccupationGroup[] }>;
      docData(occupationGroupsDocRef).pipe(
        take(1),
        tap(res => {
          if (res) {
            this.occupationGroups.next(res.occupationGroups);
          } else {
            console.log('missing data for occupations');
          }
        })
      ).subscribe();
    }
  }

  loadOnet27Occupations() {
    if (!this.onet27occupationGroups.value) {
      const onet27occupationGroupsDocRef = doc(this.firestore, 'occupations/onet27occupationGroups') as DocumentReference<{ occupationGroups: OccupationGroup[] }>;
      docData(onet27occupationGroupsDocRef).pipe(
        take(1),
        tap(res => {
          if (res) {
            this.onet27occupationGroups.next(res.occupationGroups);
          } else {
            console.log('missing data for occupations');
          }
        })
      ).subscribe();
    }
  }

  loadWorkgroups() {
    if (!this.workgroups.value) {
      const workgroupsDocRef = doc(this.firestore, 'occupations/workgroups') as DocumentReference<{ workgroups: WorkGroup[] }>;
      docData(workgroupsDocRef).pipe(
        take(1),
        tap(res => {
          if (res) {
            this.workgroups.next(res.workgroups);
          } else {
            console.log('missing data for workgroups');
          }
        })
      ).subscribe();
    }
  }

  loadClusters() {
    if (!this.clusters.value) {
      const clustersDocRef = doc(this.firestore, 'occupations/clusters') as DocumentReference<{ clusters: Cluster[] }>;
      docData(clustersDocRef).pipe(
        take(1),
        tap(res => {
          if (res) {
            this.clusters.next(res.clusters);
          } else {
            console.log('missing data for clusters');
          }
        })
      ).subscribe();
    }
  }

  getWorkgroup(id: number): EvalueeWorkGroup | null {
    return this.evalueeWorkGroups?.value?.find(wg => wg.id === id) || null;
  }

  useFindWorkgroups(screenType: 'interest' | 'aptitude' | 'default'): EvalueeWorkGroup[] {
    return findWorkgroups(this.evalueeWorkGroups.value, screenType);
  }

  // O*NET Occupations are derived from the subset of filtered workgroups passed.
  findOccupations(workgroups: WorkGroup[], occupationGroups: OccupationGroup[]): OccupationGroup[] {
    const groups: OccupationGroup[] = [];

    const workGroupIds = workgroups.map(wg => wg.id);

    occupationGroups.forEach(og => {
      // Make a copy of the occupation group to not impact it's occupations with the filter
      const newGroup = JSON.parse(JSON.stringify(og)) as OccupationGroup;
      // Combination of filter with some that runs truthy for each workgroup id if included in list of aligned workgroup ids
      newGroup.occupations = og.occupations?.filter(occupation => occupation.workGroupIds.some(wgId => (workGroupIds.includes(wgId))));
      newGroup.occupations?.sort((a, b) => a.code.localeCompare(b.code));
      if (newGroup.occupations && newGroup.occupations.length) {

        newGroup.occupations.forEach(occ => {
          // For each occupation, get unique list of interests from workgroupIds
          // TODO: Fix THIS - this should not have an || 'Phy'
          occ.interestCategories = Array.from(new Set(occ.workGroupIds.map((wgId: number) => this.getWorkgroup(wgId)?.interestCategory || 'Phy')));
          // ! New system looks for any workgroup tied to the occupation that qualifies
          // Old system will only look at workgroups that the evaluee has a ranked interest
          occ.qualifiedAptitude = occ.workGroupIds.map((wgId: number) => this.getWorkgroup(wgId)?.aptitudeQualify).includes(true);
          occ.qualifiedAptitudeSEM = occ.workGroupIds.map((wgId: number) => this.getWorkgroup(wgId)?.aptitudeQualifySEM).includes(true);
        });
        groups.push(newGroup);
      }
    });

    return groups;
  }

  findClusters(assessment: Assessment): { clusters: Cluster[] | null, avgPathwayPct: number | null; } {
    const clusters: Cluster[] = [];
    const pathwayPercents: number[] = [];

    if (this.clusters.value) {

      this.clusters.value.forEach(cluster => {
        const newPathways: Pathway[] = [];
        cluster.pathways?.forEach(pathway => {
          const specialties = pathway.specialties?.filter(() => true);

          specialties?.forEach(spec => {
            // Tag speciality if interest if an interest category is a top interest
            spec.topInterest = false;
            spec.interestCategories.forEach(category => {
              if (assessment.topInterestScores.findIndex(topInt => topInt.interestCategory === category) > -1) {
                spec.topInterest = true;
              }
            });

            // Tag speciality for low aptitude
            spec.aptitudeQualify = true;
            spec.aptitudeQualifySEM = true;
            spec.aptitudeMinimums?.forEach(aMin => {
              const specQualify = assessment.aptitudeScores.findIndex(aptScore => aptScore.aptitude === aMin.aptitude && aptScore.grade_adjusted_score < aMin.score) === -1;
              const specQualifySEM = assessment.aptitudeScores.findIndex(aptScore => aptScore.aptitude === aMin.aptitude && aptScore.SEM_adjusted_score < aMin.score) === -1;

              spec.aptitudeQualify = spec.aptitudeQualify && specQualify;
              spec.aptitudeQualifySEM = spec.aptitudeQualifySEM && specQualifySEM;
            });

          });
          // Always use SEM?
          if (pathway.specialties) {
            const totalQualified = pathway.specialties?.filter(spec => spec.topInterest && spec.aptitudeQualifySEM).length;
            pathway.interestAndAptitudePercent = totalQualified / pathway.specialties.length;
            pathwayPercents.push(pathway.interestAndAptitudePercent);
          }

          const newPathway = JSON.parse(JSON.stringify(pathway));
          newPathway.specialties = pathway.specialties?.filter(specialty => specialty.show);
          // console.log('Pathway', pathway.code, pathway.name, totalQualified, pathway.specialties.length, pathway.interestAndAptitudePercent);
          newPathways.push(newPathway);

        });

        // Make a copy of cluster
        const newCluster = JSON.parse(JSON.stringify(cluster));
        newCluster.pathways = newPathways;

        clusters.push(newCluster);


      });

      const pathwayPctSum = this.sum(pathwayPercents);
      const pathwayPctAvg = pathwayPctSum / 77;
      const pathwayPctStdDev = this.standardDeviation(pathwayPercents);


      // console.log(pathwayPctAvg, pathwayPctStdDev, pathwayPctAvg + pathwayPctStdDev / 2);
      // Mark the pathways to include as significant if the pathway is at least a half a standard deviation from the average
      const clusterPercents: number[] = [];
      clusters.forEach(cluster => {
        cluster.pathways?.forEach(pathway => {
          if (pathway.interestAndAptitudePercent) {
            pathway.include = pathway.interestAndAptitudePercent > pathwayPctAvg + pathwayPctStdDev / 2;
          }
        });
        if (cluster.pathways) {
          cluster.pathwayPercent = cluster.pathways.filter(pathway => pathway.include).length / cluster.pathways.length;
          clusterPercents.push(cluster.pathwayPercent);
        }
      });

      // Set Cluster Rank to all clusters with pathway percents greater than the mean plus a half a standard deviation
      const clusterPctAvg = this.average(clusterPercents);
      const clusterPctStdDev = this.standardDeviation(clusterPercents);
      const orderedClusters = clusters.sort((a, b) => (b.pathwayPercent || 0) - (a.pathwayPercent || 0));

      let rank = 1;
      orderedClusters.forEach(cluster => {
        // Set Cluster Rank to all clusters with pathway percents greater than the mean plus a half a standard deviation
        cluster.rank = cluster.pathwayPercent && cluster.pathwayPercent > clusterPctAvg + clusterPctStdDev / 2 ? rank++ : 0;
        // Only include pathways marked as include for reporting
        cluster.pathways = cluster.pathways?.filter(pathway => pathway.include);
      });

      // Only include ranked clusters for reporting
      const topClusters = orderedClusters.filter(cluster => cluster.rank && cluster.rank > 0);

      // Sort clusters by their code
      return { clusters: topClusters.sort((a, b) => Number(a.code) - Number(b.code)), avgPathwayPct: pathwayPctAvg };
    }
    // Return empty set if there are no clusters
    return { clusters: null, avgPathwayPct: null };
  }

  /* Math help functions for stastical analysis of clusters */

  standardDeviation(values: number[]) {
    const avg = this.average(values);

    const squareDiffs = values.map((value) => {
      const diff = value - avg;
      const sqrDiff = diff * diff;
      return sqrDiff;
    });

    const avgSquareDiff = this.average(squareDiffs);

    const stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
  }

  average(data: number[]) {
    const total = data.reduce((sum, value) => {
      return sum + value;
    }, 0);

    const avg = total / data.length;
    return avg;
  }

  sum(data: number[]) {
    const total = data.reduce((sum, value) => {
      return sum + value;
    }, 0);

    return total;
  }

}

