import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, skipWhile, switchMap } from 'rxjs/operators';
import { PortalService } from '../services/portal.service';

@Injectable({
  providedIn: 'root'
})
export class PortalExistsGuard implements CanActivate {

  constructor(private ps: PortalService) { }

  canActivate(): Observable<boolean> {
      return this.ps.portal.pipe(
        skipWhile(portal => portal === null), 
        map(portal => !!portal),
        switchMap(portal => of(!!portal))
      );
  }
  
}
