<div class="search">
  <mat-form-field subscriptSizing="dynamic" appearance="outline">
    <input matInput type="text" placeholder="search evaluees or managers..." [formControl]="searchControl" [matAutocomplete]="searchAuto">

    <mat-icon matPrefix>search</mat-icon>

    <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelected()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-autocomplete #searchAuto="matAutocomplete" autoActiveFirstOption [displayWith]="getName" [panelWidth]="300" (optionSelected)="searchItemSelected($event.option.value)">
    <mat-optgroup *ngFor="let group of lists$ | async" [label]="group.type">
      <mat-option *ngFor="let item of group.searchItems" [value]="item">
        {{item.fullName}}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</div>