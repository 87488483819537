import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, skipWhile, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HqAccessGuard implements CanActivate {
  
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
      return this.auth.user$.pipe(
        skipWhile(user => user === null), 
        switchMap(user => {
          if(!user?.hqAccess) {
            this.router.navigateByUrl("/");
            return of(false);
          }
          return of(user.hqAccess);
        })
      );
  }
  
}
