import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdminUser } from '@career-scope/models';
import { Subscription } from 'rxjs';
import { PredefinedMessageTypes } from '../models/supportMessage.model';
import { AuthService } from '../services/auth.service';
import { PortalService } from '../services/portal.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-support-message-dialog',
  templateUrl: './support-message-dialog.component.html',
  styleUrls: ['./support-message-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class SupportMessageDialogComponent implements OnInit, OnDestroy {
  form?: FormGroup;
  authSubscription?: Subscription;
  predefinedMessageTypes = PredefinedMessageTypes;

  constructor(
    public dialogRef: MatDialogRef<SupportMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PredefinedMessageTypes,
    public ps: PortalService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.auth.user$.subscribe(user => {
      this.form = this.initForm(user);
    });
  }

  initForm(user: AdminUser | null | undefined) {
    return new FormGroup({
      email: new FormControl({ disabled: true, value: user?.email || '' }, [Validators.required]),
      phone: new FormControl(''),
      message: new FormControl(this.data ? this.data : '', [Validators.required])
    });
  }

  get email() {
    return this.form?.get('email') as FormControl;
  }

  get message() {
    return this.form?.get('message') as FormControl;
  }

  async sendMessage() {
    const user = await this.auth.currentUser();
    if (this.form?.valid) {
      this.ps.sendSupportEmail({ ...this.form?.getRawValue(), uid: user?.uid, name: user?.name });
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

}
