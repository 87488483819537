const projectId = 'careerscope-vri';

export const environment = {
  production: true,
  useEmulators: false,
  dev: false,
  vx: false,
  firebase: {
    apiKey: 'AIzaSyC9PQ5yqBQzQ3B-ZnTx5sNVglwP9Sxop9g',
    authDomain: 'careerscope-vri.firebaseapp.com',
    databaseURL: 'https://careerscope-vri.firebaseio.com',
    projectId,
    storageBucket: 'careerscope-vri.appspot.com',
    messagingSenderId: '748287607122',
    appId: '1:748287607122:web:bbc2655959d73e157a3a8b',
    measurementId: 'G-WCH5EK1BLV'
  },
  evalueeInvitationURL: 'https://app.careerscope.com/login/',
  evalueeUsernameURL: 'https://app.careerscope.com/username/',
  managerInvitationURL: 'https://admin.careerscope.com/login/',
  portalInvitationURL: 'https://app.careerscope.com/portal/',
  functionsURL: `https://admin.careerscope.com`
};
